import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';
import { API, CLIENT, ASSETS_URL } from '../_helpers/Constants';


export default class Horizontal_1 extends Component {
  constructor(props){
    super(props);
    this.state = {
        total : props.total,
        type : props.type,
        category : props.category,
        categoryID : props.categoryID ? props.categoryID : null,
        titleColor : props.titleColor ? props.titleColor : "#000",
        data : [],
        client : JSON.parse(localStorage.getItem('_profile')),
    }
  }


  ImageHelper(ev){
    var client = JSON.parse(localStorage.getItem('_profile'));
    ev.target.src = ASSETS_URL + "img/logo/" + client.subdomain_logo
  }
  
  
  componentDidMount(){
    this.getDataSlider();
  }
  getDataSlider(){
      var self = this;
	  var typeID = 1;
	if (this.state.type == 'printed' )
	{
	  typeID = 3;
	}else if(this.state.type == 'online')
	{
	  typeID = 1;

	}else if (this.state.type == 'radio')
	{
	  typeID = 4;

	}else if(this.state.type == 'onlineprinted')
	{
	  typeID = 99;

	}else{
	  typeID = 2;

	}
    var urls =  API + "news/getall?username="+ localStorage.getItem('_username')
        + "&token="+ localStorage.getItem('_token') 
        + "&subdomain_id=" + CLIENT
        + (this.state.categoryID != null ? '&topic='+ this.state.categoryID : null)
        + "&limit="+ this.state.total
        +( this.state.type ===  '' ? '' : ( "&type="+typeID ))
        + "&date_from=2017-01-01&date_to=2022-12-31";
    // console.log(urls);
    axios({
        method: 'get',
          url: urls,
      }).then(function (response) {
          if(response.data.status ===  200){
            //   console.log(response.data.data);
            self.setState({ data : response.data.data});
          }else{
            // alert(response.data.message);
          }
      }).catch(function (error) {
           
      });
  }

 


 
  render(){
    return (
        <div>
            
            
            <div className="popular-news-area section-padding-80-50">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <div className="section-heading" >
                                <h6 style={{backgroundColor:this.state.client.subdomain_color}}>{this.state.category}</h6>
                            </div>

                            <div className="row">
                                { this.state.data.map((item, i) => {

                                        if(this.state.type != 'tv' && this.state.type != 'radio') {
                                            return <div key={i} className="col-12 col-md-3">
                                                        <div className="single-blog-post style-3">
                                                            <div className="post-thumb">
                                                                <Link to={`/news/${item.berita_id}`}  ><img  style={{
                                                                                        alignSelf: 'contain',
                                                                                        height: 160,
                                                                                        width: '100%',
                                                                                    }}
                                                                                    onError={this.ImageHelper}
                                                                                    src={ASSETS_URL + "/img/photo/" + item.berita_foto} alt="" /></Link>
                                                            </div>
                                                            <div className="post-data">
                                                                <Link to={`/news/${item.berita_id}`}   className="post-catagory">{this.state.type}</Link>
                                                                <Link to={`/news/${item.berita_id}`}   className="post-title" >
                                                                    <h6 style={{color:this.state.titleColor}}>{item.berita_judul}</h6>
                                                                </Link>
                                                                <p className="post-date"><span>{item.berita_jam_tampil}</span> | <span>{item.berita_tanggal_tampil}</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                        }else if(this.state.type === 'radio') {
                                            return <div key={i} className="col-12 col-md-3">
                                                        <div className="single-blog-post style-3">
                                                            <div className="post-thumb">
                                                               <iframe style={{
                                                                            alignSelf: 'contain',
                                                                            height: 130,
                                                                            width: '100%',
                                                                        }} src={item.berita_radio}></iframe>
                                                            </div>
                                                            <div className="post-data">
                                                                <Link to={`/news/${item.berita_id}`}   className="post-catagory">{item.media_nama}</Link>
                                                                <Link to={`/news/${item.berita_id}`}   className="post-title" >
                                                                    <h6 style={{color:this.state.titleColor}}>{item.berita_judul}</h6>
                                                                </Link>
                                                                <p className="post-date"><span>{item.berita_jam_tampil}</span> | <span>{item.berita_tanggal_tampil}</span></p>
                                                            </div>
                                                        </div>
                                                </div>
                                        }else{
                                            return <div key={i} className="col-12 col-md-3">
                                                        <div className="single-blog-post style-3">
                                                            <div className="post-thumb">
                                                                <iframe style={{
                                                                            alignSelf: 'contain',
                                                                            height: 130,
                                                                            width: '100%',
                                                                        }} src={item.berita_link}></iframe>
                                                            </div>
                                                            <div className="post-data">
                                                                <Link to={`/news/${item.berita_id}`}   className="post-catagory">{item.media_nama}</Link>
                                                                <Link to={`/news/${item.berita_id}`}   className="post-title" >
                                                                    <h6 style={{color:this.state.titleColor}}>{item.berita_judul}</h6>
                                                                </Link>
                                                                <p className="post-date"><span>{item.berita_jam_tampil}</span> | <span>{item.berita_tanggal_tampil}</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        )
  }
 
}




